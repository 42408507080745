import { FC } from "react";
import { CellContext } from "@tanstack/react-table";
import { IPrice } from "../../@types/graphql";
import { useReactiveVar } from "@apollo/client";
import { pricesChangedRows } from "../../store/prices.store";
import { CustomInput } from "../../components/customInput";
import { restrictNumbers } from "../../helpers/common";

export const PriceCell: FC<CellContext<IPrice, number>> = (d) => {
  const id = d.row.original.id;
  const editRows = useReactiveVar(pricesChangedRows);

  const handleChange = (value: string) => {
    const onlyNumbersValue = Math.min(+restrictNumbers(value));
    const prev = {
      steps: d.row.original.steps,
      sku: d.row.original.sku,
      id: d.row.original.id,
      markups: d.row.original.markups,
      comment: d.row.original?.comment || "",
      ...(editRows[id] || {}),
    };
    pricesChangedRows({
      ...editRows,
      [id]: { ...prev, price: onlyNumbersValue },
    });
  };

  return (
    <>
      <CustomInput
        wrapperClassname="!inline-block max-w-full"
        autoWidth
        className="!text-primary700 !bg-transparent !p-0 min-w-10 max-w-full"
        value={editRows?.[id]?.price ?? `${d.getValue()}`}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />{" "}
      RUB
    </>
  );
};
